


















import Vue from 'vue';

export default Vue.extend({
	props: {
		icon: { type: String, default: null },
		heading: { type: String, required: true },
		content: { type: String, required: true },
	},
});
